
import Vue from "vue";
export default Vue.extend({
  props: {
    link: {
      type: String,
      required: true,
    },
    webp: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "",
    },
    alt: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
  },
  computed: {
    isExternalLink() {
      return /^https?:\/\//.test(this.link);
    },
  },
});
